.starlink {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
}

.content {
    position: relative;
    width: 100%;
    z-index: 1;
}

.section {
    height: 100vh;
    border-bottom: 1px solid red;
}